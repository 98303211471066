
import { defineComponent, inject, onMounted, reactive, toRefs } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FileTabs from "@/components/atomics/file-tabs/FileTabs.vue";
import FileTab from "@/components/atomics/file-tabs/FileTab.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";

import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
import PdfPreviewVars from "@/components/parts/PdfPreviewVars.vue";

import SignTextField from "@/components/atomics/sign-fields/SignTextField.vue";
import SignStampField from "@/components/atomics/sign-fields/SignStampField.vue";
import SignCheckboxField from "@/components/atomics/sign-fields/SignCheckboxField.vue";

import AvatarCircle from "@/components/organisms/AvatarCircle.vue";

import ContractHistoryPopup from "@/components/popups/ContractHistoryPopup.vue";

import { ContractRepository, RepositoryFactory } from "@/lib/https";
import { mapMutations } from "vuex";

import { useRoute, useRouter } from "vue-router";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";

import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

export default defineComponent({
  name: "ContractDetailReadonly",
  components: {
    TextButton,
    FileTab,
    FileTabs,
    Dropdown,
    PdfPreviewVars,
    SignTextField,
    SignStampField,
    SignCheckboxField,
    AfterLoginPageLayout,
    MenuOptionPopup,
    ContractHistoryPopup,
    AvatarCircle,
    Vue3Popper,
  },
  props: {
    isUser: { type: Boolean, default: false },
  },
  setup(props) {
    const state = reactive({
      showOptionType: 1,
      contractState: {} as any,
      tabFileNames: [] as any,
      numberOfSigned: 0,
      isMounted: false,
    });

    const fileState = reactive({
      page: 1,
      numPages: 1,
      selectedFile: {} as any,
    });
    const isMobile = inject("isMobile");
    const route = useRoute();
    const router = useRouter();

    const { getGuestContractInformation, getContractFullInformation } =
      contractDetailMethods();

    const {
      getNumberOfAlreadySignMember,
      getFileMappingIdAndName,
      haveBeenNotBeSigned,

      getAccessCode,
    } = useSignFunctions();

    const setGeneralInformation = async (contractInformation: any) => {
      state.contractState = contractInformation;
      state.tabFileNames = getFileMappingIdAndName(
        contractInformation.contractFiles ?? []
      );
    };

    onMounted(async () => {
      try {
        state.isMounted = true;
        const accessCode = getAccessCode(route.query.contractId as string);

        let contractFullInformation;
        let singedCount = 0;

        if (props.isUser) {
          contractFullInformation = await getContractFullInformation(
            route.query.contractId as string,
            accessCode
          );
        } else {
          contractFullInformation = await getGuestContractInformation(
            accessCode
          );
        }
        singedCount = contractFullInformation.countSigned;
        contractFullInformation.contractFiles.forEach((file: any) => {
          const fileInputs = file.contractVars ?? [];
          file.contractVars = fileInputs
            .filter((field: any) => field.contractSignerOrder > singedCount)
            .map((input: any) => {
              input.x = input.positionX;
              input.y = input.positionY;
              input.w = input.width;
              input.h = input.height;
              return input;
            });
        });

        setGeneralInformation(contractFullInformation);
        fileState.selectedFile = contractFullInformation.contractFiles[0];
        state.numberOfSigned = getNumberOfAlreadySignMember(
          contractFullInformation.contractSigners || []
        );
      } catch (e: any) {
        const errorMessage = e?.data?.message || "";
        router.push({
          name: "ErrorPage",
          query: {
            error: errorMessage,
          },
        });
      }
    });

    return {
      ...toRefs(state),
      ...toRefs(fileState),
      getFilePathFromUrl,
      haveBeenNotBeSigned,
      isMobile,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.fullPath.includes("/sign/access-code") ||
      to.fullPath.includes("/sign/terms")
    )
      next({ name: "Login" });
    else next();
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
    // general function
    download(url: string) {
      const link = document.createElement("a");
      link.href = getFilePathFromUrl(url);
      link.target = "_blank";
      link.click();
    },

    // Modified PDF view function
    onReady(file: any, pageNums: number) {
      this.numPages = pageNums;
      file.pageNums = pageNums;

      file.loadDone = true;
    },
    onPageScaled(file: any, scale: any) {
      if (file.scale == 1 || !file.scale) {
        this.applyScale(file, scale);
      }

      if (file.scale && file.scale !== scale) {
        this.applyScale(file, scale);
      }
    },

    applyScale(file: any, scale: number) {
      file.scale = scale;
      file.contractVars?.forEach((input: any) => {
        if (input.contractFileId === file.id && !input.isSaved) {
          input.width = input.w * scale;
          input.height = input.h * scale;
          input.positionX = input.x * scale;
          input.positionY = input.y * scale;
          input.scale = scale;
        }
      });
    },
    increasePage() {
      if (this.page < this.numPages) {
        this.page = this.page + 1;
      }
    },
    decreasePage() {
      if (this.page > 1) {
        this.page = this.page - 1;
      }
    },
    setPage(e: any) {
      if (!this.page && !this.selectedFile.pageNums) {
        this.selectedFile.currentPage = 1;
        return;
      }
      const page = parseInt(e.target.value, 10);
      if (page <= 0) {
        this.page = 1;
        e.target.value = 1;
        return;
      } else if (page >= (this.selectedFile.pageNums || 0)) {
        this.page = this.selectedFile.pageNums;
        e.target.value = this.selectedFile.pageNums;
        return;
      } else if (page) this.page = page;
      if (e.data != null) {
        this.page = parseInt(e.data);
      }
    },
    onChangeTab(value: number) {
      if (this.contractState.contractFiles) {
        this.selectedFile = this.contractState.contractFiles[value];
        this.page = 1;
      }
    },
    onChangeDropdown(val: any) {
      if (this.contractState.contractFiles) {
        const index = this.contractState.contractFiles.findIndex(
          (file: any) => file.id === val
        );

        if (index >= 0) this.onChangeTab(index);
      }
    },

    viewHistory() {
      this.setPopup("ContractHistory");
    },

    viewTransfer() {
      this.setPopup("ContractTransfer");
    },

    showSignerList() {
      this.showOptionType = 3;
    },

    showViewerList() {
      this.showOptionType = 2;
    },

    backToFileViewerOption() {
      this.showOptionType = 1;
    },
  },
});

const contractDetailMethods = () => {
  const { getGuestContractInformation, getContractFullInformation } =
    RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

  return {
    getGuestContractInformation,
    getContractFullInformation,
  };
};
