import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, Teleport as _Teleport, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74222672"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-area justify-end" }
const _hoisted_2 = {
  key: 0,
  class: "contract-name-title mb-1"
}
const _hoisted_3 = { class: "long-name" }
const _hoisted_4 = {
  key: 0,
  class: "contract-name-title mb-1"
}
const _hoisted_5 = { class: "long-name" }
const _hoisted_6 = { class: "file-header" }
const _hoisted_7 = { class: "left-header" }
const _hoisted_8 = { class: "user-info" }
const _hoisted_9 = { class: "viewer-name ml-1" }
const _hoisted_10 = { class: "email" }
const _hoisted_11 = { class: "view-imgs" }
const _hoisted_12 = { class: "user-info" }
const _hoisted_13 = { class: "signer-name" }
const _hoisted_14 = { class: "email" }
const _hoisted_15 = {
  key: 0,
  class: "status signed"
}
const _hoisted_16 = {
  key: 1,
  class: "status rejected"
}
const _hoisted_17 = {
  key: 2,
  class: "status"
}
const _hoisted_18 = { class: "right-header" }
const _hoisted_19 = ["value"]
const _hoisted_20 = { class: "file-content" }
const _hoisted_21 = { class: "sheet" }
const _hoisted_22 = {
  key: 1,
  class: "mobile-file-viewer"
}
const _hoisted_23 = { class: "file-header" }
const _hoisted_24 = { class: "option" }
const _hoisted_25 = { class: "option" }
const _hoisted_26 = { class: "option" }
const _hoisted_27 = { class: "viewer" }
const _hoisted_28 = { class: "option signer-option" }
const _hoisted_29 = { class: "view-imgs" }
const _hoisted_30 = { class: "viewer-header" }
const _hoisted_31 = { class: "user-info" }
const _hoisted_32 = { class: "viewer-name ml-1" }
const _hoisted_33 = { class: "email" }
const _hoisted_34 = { class: "viewer-header" }
const _hoisted_35 = { class: "user-info" }
const _hoisted_36 = { class: "signer-name" }
const _hoisted_37 = { class: "email" }
const _hoisted_38 = {
  key: 0,
  class: "status signed"
}
const _hoisted_39 = {
  key: 1,
  class: "status rejected"
}
const _hoisted_40 = {
  key: 2,
  class: "status"
}
const _hoisted_41 = { class: "file-content" }
const _hoisted_42 = { class: "sheet" }
const _hoisted_43 = { class: "file-header file-footer" }
const _hoisted_44 = { class: "right-header" }
const _hoisted_45 = { class: "button-in-row" }
const _hoisted_46 = ["value"]
const _hoisted_47 = { class: "button-in-row move-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_button = _resolveComponent("text-button")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_AvatarCircle = _resolveComponent("AvatarCircle")!
  const _component_Vue3Popper = _resolveComponent("Vue3Popper")!
  const _component_MenuOptionPopup = _resolveComponent("MenuOptionPopup")!
  const _component_SignTextField = _resolveComponent("SignTextField")!
  const _component_SignStampField = _resolveComponent("SignStampField")!
  const _component_SignCheckboxField = _resolveComponent("SignCheckboxField")!
  const _component_PdfPreviewVars = _resolveComponent("PdfPreviewVars")!
  const _component_FileTab = _resolveComponent("FileTab")!
  const _component_FileTabs = _resolveComponent("FileTabs")!
  const _component_dropdown = _resolveComponent("dropdown")!
  const _component_AfterLoginPageLayout = _resolveComponent("AfterLoginPageLayout")!
  const _component_ContractHistoryPopup = _resolveComponent("ContractHistoryPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AfterLoginPageLayout, { class: "main-content" }, {
      title: _withCtx(() => [
        (_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.contractState.title), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      topLeft: _withCtx(() => [
        (!_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.contractState.title), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      bottomLeft: _withCtx(({ detectScrollbar }) => [
        (!_ctx.isMobile && _ctx.isMounted)
          ? (_openBlock(), _createBlock(_component_FileTabs, {
              key: 0,
              "tab-name": _ctx.tabFileNames,
              onOnChangeTab: _ctx.onChangeTab,
              class: "file-tab",
              "teleport-tabs-header-to": ".top-part"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractState.contractFiles, (file, index) => {
                  return (_openBlock(), _createBlock(_component_FileTab, {
                    key: index,
                    name: file.fileName
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_Teleport, { to: ".top-part" }, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_text_button, {
                              onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.download(_ctx.contractState.zipFilePath))),
                              text: _ctx.$t('common.download'),
                              icon: require('icons/download.svg'),
                              class: "button-in-row"
                            }, null, 8, ["text", "icon"]),
                            _createVNode(_component_text_button, {
                              onOnClick: _ctx.viewHistory,
                              text: _ctx.$t('sign.viewHistory'),
                              icon: require('icons/view-history.svg'),
                              class: "button-in-row"
                            }, null, 8, ["onOnClick", "text", "icon"]),
                            (_ctx.contractState.contractViewers)
                              ? (_openBlock(), _createBlock(_component_MenuOptionPopup, {
                                  key: 0,
                                  class: "mr-4 user-popup"
                                }, {
                                  target: _withCtx(({ showMenuOption }) => [
                                    _createVNode(_component_TextButton, {
                                      icon: require('icons/view.svg'),
                                      text: _ctx.$t('sign.viewViewer'),
                                      onOnClick: showMenuOption
                                    }, null, 8, ["icon", "text", "onOnClick"])
                                  ]),
                                  options: _withCtx(() => [
                                    _createElementVNode("ul", null, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractState.contractViewers, (viewer, index) => {
                                        return (_openBlock(), _createElementBlock("li", {
                                          key: viewer.id,
                                          class: _normalizeClass([{ 'first-result': index == 0 }, 'detail'])
                                        }, [
                                          _createElementVNode("div", _hoisted_8, [
                                            _createVNode(_component_AvatarCircle, {
                                              avatar: 
                                viewer.profilePicture
                                  ? _ctx.getFilePathFromUrl(viewer.profilePicture)
                                  : require('icons/default-avatar.svg')
                              ,
                                              alt: "avatar",
                                              class: "avatar"
                                            }, null, 8, ["avatar"]),
                                            _createElementVNode("div", _hoisted_9, [
                                              _createElementVNode("p", null, _toDisplayString(viewer.viewerLastName) + " " + _toDisplayString(viewer.viewerFirstName), 1),
                                              _createVNode(_component_Vue3Popper, {
                                                content: viewer.viewerEmail
                                              }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", _hoisted_10, _toDisplayString(viewer.viewerEmail), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["content"])
                                            ])
                                          ])
                                        ], 2))
                                      }), 128))
                                    ])
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true),
                            (_ctx.contractState.contractSigners)
                              ? (_openBlock(), _createBlock(_component_MenuOptionPopup, {
                                  key: 1,
                                  class: "signer"
                                }, {
                                  target: _withCtx(({ showMenuOption }) => [
                                    _createElementVNode("div", _hoisted_11, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractState.contractSigners.slice(
                            0,
                            3
                          ), (signer) => {
                                        return (_openBlock(), _createBlock(_component_AvatarCircle, {
                                          key: signer.id,
                                          avatar: 
                            signer.profilePicture
                              ? _ctx.getFilePathFromUrl(signer.profilePicture)
                              : require('icons/default-avatar.svg')
                          ,
                                          alt: "view-img",
                                          class: "view-img"
                                        }, null, 8, ["avatar"]))
                                      }), 128))
                                    ]),
                                    _createVNode(_component_TextButton, {
                                      text: 
                          _ctx.numberOfSigned +
                          '/' +
                          _ctx.contractState.contractSigners.length +
                          _ctx.$t('sign.signer')
                        ,
                                      onOnClick: showMenuOption
                                    }, null, 8, ["text", "onOnClick"])
                                  ]),
                                  options: _withCtx(() => [
                                    _createElementVNode("ul", null, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractState.contractSigners, (signer, index) => {
                                        return (_openBlock(), _createElementBlock("li", {
                                          key: signer.id,
                                          class: _normalizeClass([{ 'first-result': index == 0 }, 'detail'])
                                        }, [
                                          _createElementVNode("div", _hoisted_12, [
                                            _createVNode(_component_AvatarCircle, {
                                              avatar: 
                                signer.profilePicture
                                  ? _ctx.getFilePathFromUrl(signer.profilePicture)
                                  : require('icons/default-avatar.svg')
                              ,
                                              alt: "avatar",
                                              class: "avatar"
                                            }, null, 8, ["avatar"]),
                                            _createElementVNode("div", _hoisted_13, [
                                              _createElementVNode("p", null, _toDisplayString(signer.signerLastName) + " " + _toDisplayString(signer.signerFirstName), 1),
                                              _createVNode(_component_Vue3Popper, {
                                                content: signer.signerEmail
                                              }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", _hoisted_14, _toDisplayString(signer.signerEmail), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["content"])
                                            ])
                                          ]),
                                          (signer.statusName == 'signed')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t("sign.signed")), 1))
                                            : (signer.statusName == 'rejected')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t("sign.rejected")), 1))
                                              : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t("sign.signed")), 1))
                                        ], 2))
                                      }), 128))
                                    ])
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("input", {
                              value: _ctx.page,
                              type: "text",
                              class: "jump-page",
                              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setPage && _ctx.setPage(...args)))
                            }, null, 40, _hoisted_19),
                            _createElementVNode("p", null, "/" + _toDisplayString(_ctx.numPages), 1),
                            _createVNode(_component_TextButton, {
                              class: "prev-page",
                              icon: require('icons/bold-prev-arrow.svg'),
                              "disabled-icon": require('icons/prev-arrow-grey.svg'),
                              enabled: _ctx.page >= 2,
                              onOnClick: _ctx.decreasePage
                            }, null, 8, ["icon", "disabled-icon", "enabled", "onOnClick"]),
                            _createVNode(_component_TextButton, {
                              class: "next-page",
                              "right-icon": require('icons/next-arrow.svg'),
                              "disabled-icon": require('icons/next-arrow-grey.svg'),
                              enabled: _ctx.page != _ctx.numPages,
                              onOnClick: _ctx.increasePage
                            }, null, 8, ["right-icon", "disabled-icon", "enabled", "onOnClick"])
                          ])
                        ])
                      ])),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          _createVNode(_component_PdfPreviewVars, {
                            "file-path": _ctx.getFilePathFromUrl(_ctx.selectedFile.filePath),
                            "file-name": _ctx.selectedFile.fileName,
                            "file-type": _ctx.selectedFile.fileType,
                            page: _ctx.page,
                            onPageLoaded: () => detectScrollbar(),
                            onOnReady: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onReady(_ctx.selectedFile, $event))),
                            onPageScaled: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onPageScaled(_ctx.selectedFile, $event)))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.selectedFile.contractVars || []).filter(
                      (field) =>
                        field.page == _ctx.page &&
                        field.contractFileId === _ctx.selectedFile.id
                    ), (field) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: field.id
                                }, [
                                  _createVNode(_component_SignTextField, {
                                    field: field,
                                    readonly: true
                                  }, null, 8, ["field"]),
                                  _createVNode(_component_SignStampField, {
                                    field: field,
                                    readonly: true
                                  }, null, 8, ["field"]),
                                  _createVNode(_component_SignCheckboxField, {
                                    field: field,
                                    readonly: true
                                  }, null, 8, ["field"])
                                ]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["file-path", "file-name", "file-type", "page", "onPageLoaded"])
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["name"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["tab-name", "onOnChangeTab"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_dropdown, {
                  class: "dropdown",
                  "default-option": _ctx.selectedFile.id,
                  options: _ctx.tabFileNames,
                  onOnChange: _ctx.onChangeDropdown
                }, null, 8, ["default-option", "options", "onOnChange"]),
                _createVNode(_component_MenuOptionPopup, {
                  "is-hidden-from-click": false,
                  class: "more-option-context"
                }, {
                  target: _withCtx(({ showMenuOption }) => [
                    _createVNode(_component_TextButton, {
                      icon: require('icons/more.svg'),
                      "is-show-text": false,
                      onOnClick: showMenuOption
                    }, null, 8, ["icon", "onOnClick"])
                  ]),
                  options: _withCtx(() => [
                    _withDirectives(_createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_24, [
                        _createVNode(_component_text_button, {
                          onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.download(_ctx.contractState.zipFilePath))),
                          text: _ctx.$t('sign.download'),
                          icon: require('icons/download.svg'),
                          class: "button-in-row"
                        }, null, 8, ["text", "icon"])
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createVNode(_component_text_button, {
                          onOnClick: _ctx.viewHistory,
                          text: _ctx.$t('sign.viewHistory'),
                          icon: require('icons/view-history.svg'),
                          class: "button-in-row"
                        }, null, 8, ["onOnClick", "text", "icon"])
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          (_ctx.contractState.contractViewers)
                            ? (_openBlock(), _createBlock(_component_text_button, {
                                key: 0,
                                onOnClick: _ctx.showViewerList,
                                text: _ctx.$t('sign.viewViewer'),
                                icon: require('icons/view.svg')
                              }, null, 8, ["onOnClick", "text", "icon"]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        (_ctx.contractState.contractSigners)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "signer",
                              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.showSignerList && _ctx.showSignerList(...args)))
                            }, [
                              _createElementVNode("div", _hoisted_29, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractState.contractSigners?.slice(
                            0,
                            3
                          ), (signer) => {
                                  return (_openBlock(), _createBlock(_component_AvatarCircle, {
                                    key: signer.id,
                                    avatar: 
                            signer.profilePicture
                              ? _ctx.getFilePathFromUrl(signer.profilePicture)
                              : require('icons/default-avatar.svg')
                          ,
                                    alt: "view-img",
                                    class: "view-img"
                                  }, null, 8, ["avatar"]))
                                }), 128))
                              ]),
                              _createVNode(_component_TextButton, {
                                text: 
                          _ctx.numberOfSigned +
                          '/' +
                          _ctx.contractState.contractSigners?.length +
                          _ctx.$t('sign.signer')
                        
                              }, null, 8, ["text"])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ], 512), [
                      [_vShow, _ctx.showOptionType == 1]
                    ]),
                    _withDirectives(_createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_30, [
                        _createVNode(_component_text_button, {
                          onOnClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.backToFileViewerOption())),
                          icon: require('icons/prev-arrow.svg'),
                          "is-show-text": false
                        }, null, 8, ["icon"])
                      ]),
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractState.contractViewers, (viewer, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            class: "detail",
                            key: index
                          }, [
                            _createElementVNode("div", _hoisted_31, [
                              _createVNode(_component_AvatarCircle, {
                                avatar: 
                            viewer.profilePicture
                              ? _ctx.getFilePathFromUrl(viewer.profilePicture)
                              : require('icons/default-avatar.svg')
                          ,
                                alt: "avatar",
                                class: "avatar"
                              }, null, 8, ["avatar"]),
                              _createElementVNode("div", _hoisted_32, [
                                _createElementVNode("p", null, _toDisplayString(viewer.viewerLastName) + " " + _toDisplayString(viewer.viewerFirstName), 1),
                                _createVNode(_component_Vue3Popper, {
                                  content: viewer.viewerEmail
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_33, _toDisplayString(viewer.viewerEmail), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["content"])
                              ])
                            ])
                          ]))
                        }), 128))
                      ])
                    ], 512), [
                      [_vShow, _ctx.showOptionType == 2]
                    ]),
                    _withDirectives(_createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_34, [
                        _createVNode(_component_text_button, {
                          onOnClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.backToFileViewerOption())),
                          icon: require('icons/prev-arrow.svg'),
                          "is-show-text": false
                        }, null, 8, ["icon"])
                      ]),
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractState.contractSigners, (signer, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            class: "detail",
                            key: index
                          }, [
                            _createElementVNode("div", _hoisted_35, [
                              _createVNode(_component_AvatarCircle, {
                                avatar: 
                            signer.profilePicture
                              ? _ctx.getFilePathFromUrl(signer.profilePicture)
                              : require('icons/default-avatar.svg')
                          ,
                                alt: "avatar",
                                class: "avatar"
                              }, null, 8, ["avatar"]),
                              _createElementVNode("div", _hoisted_36, [
                                _createElementVNode("p", null, _toDisplayString(signer.signerLastName) + " " + _toDisplayString(signer.signerFirstName), 1),
                                _createVNode(_component_Vue3Popper, {
                                  content: signer.signerEmail
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_37, _toDisplayString(signer.signerEmail), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["content"])
                              ])
                            ]),
                            (signer.statusName == 'signed')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_38, _toDisplayString(_ctx.$t("sign.signed")), 1))
                              : (signer.statusName == 'rejected')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString(_ctx.$t("sign.rejected")), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_40, _toDisplayString(_ctx.$t("sign.signed")), 1))
                          ]))
                        }), 128))
                      ])
                    ], 512), [
                      [_vShow, _ctx.showOptionType == 3]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _createVNode(_component_PdfPreviewVars, {
                    "file-path": _ctx.getFilePathFromUrl(_ctx.selectedFile.filePath),
                    "file-name": _ctx.selectedFile.fileName,
                    "file-type": _ctx.selectedFile.fileType,
                    page: _ctx.page,
                    onOnReady: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onReady(_ctx.selectedFile, $event))),
                    onPageScaled: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onPageScaled(_ctx.selectedFile, $event)))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.selectedFile.contractVars || []).filter(
                    (f) =>
                      f.page == _ctx.page && f.contractFileId === _ctx.selectedFile.id
                  ), (field) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: field.id
                        }, [
                          _createVNode(_component_SignTextField, {
                            field: field,
                            readonly: true
                          }, null, 8, ["field"]),
                          _createVNode(_component_SignStampField, {
                            field: field,
                            readonly: true
                          }, null, 8, ["field"]),
                          _createVNode(_component_SignCheckboxField, {
                            field: field,
                            readonly: true
                          }, null, 8, ["field"])
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["file-path", "file-name", "file-type", "page"])
                ])
              ]),
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("input", {
                      type: "text",
                      class: "jump-page",
                      value: _ctx.page,
                      onInput: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.setPage && _ctx.setPage(...args)))
                    }, null, 40, _hoisted_46),
                    _createElementVNode("p", null, "/" + _toDisplayString(_ctx.numPages), 1)
                  ]),
                  _createElementVNode("div", _hoisted_47, [
                    _createVNode(_component_TextButton, {
                      class: "prev-page",
                      icon: require('icons/bold-prev-arrow.svg'),
                      "disabled-icon": require('icons/prev-arrow-grey.svg'),
                      enabled: _ctx.page >= 2,
                      onOnClick: _ctx.decreasePage
                    }, null, 8, ["icon", "disabled-icon", "enabled", "onOnClick"]),
                    _createVNode(_component_TextButton, {
                      class: "next-page",
                      "right-icon": require('icons/next-arrow.svg'),
                      "disabled-icon": require('icons/next-arrow-grey.svg'),
                      enabled: _ctx.page != _ctx.numPages,
                      onOnClick: _ctx.increasePage
                    }, null, 8, ["right-icon", "disabled-icon", "enabled", "onOnClick"])
                  ])
                ])
              ])
            ]))
      ]),
      _: 1
    }),
    _createVNode(_component_ContractHistoryPopup, {
      "contract-title": _ctx.contractState.title,
      "contract-id": _ctx.contractState.id,
      "is-guest": !_ctx.isUser
    }, null, 8, ["contract-title", "contract-id", "is-guest"])
  ]))
}